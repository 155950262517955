// - - - - - - - - - - - - - - - - - -

// Colours

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Light Mode

body {
	background: $background-color;
	color: $text-medium-color;
}

h1, h2, h3, h4, h5, h6 {
	color: $text-dark-color;
}

a {
	color: $accent-color;

	&:hover {
		color: $text-dark-color;
	}
}

.button {
	border-color:$accent-color;
	background: $accent-color;
	color: $background-color;

	&:hover {
		background: adjust-hue($accent-color,15%);
		border-color: adjust-hue($accent-color,15%);
		color: $background-color;
	}
}

.button--light {
	background: transparent;
	border-color: $text-dark-color;
	color: $text-dark-color;

	&:hover {
		background: $accent-color;
		border-color: $accent-color;
		color: $background-color;
	}
}

.button--overlay {
	background: transparent;
	border-color: $overlay-text-color;
	color: $overlay-text-color;

	&:hover {
		background: $overlay-text-color;
		border-color: $overlay-text-color;
		color: $accent-color;
	}
}

hr {
	background: $border-color;
}

::selection 	 { background: $accent-color; color: $background-color; }
::-moz-selection { background: $accent-color; color: $background-color; }

.loader {
	svg {
		fill: rgba($text-dark-color,0.2);
	}
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Dark Mode
// @media (prefers-color-scheme: dark) {
//   body {
//     background: $background-dm-color;
//     color: $text-medium-dm-color;
//   }

//   h1, h2, h3, h4, h5, h6 {
//     color: $text-dark-dm-color;
//   }

//   a {
//     color: $accent-dm-color;

//     &:hover {
//       color: $text-dark-dm-color;
//     }
//   }

//   .button {
//     border-color:$accent-dm-color;
//     background: $accent-dm-color;
//     color: $text-dark-dm-color;

//     &:hover {
//       background: adjust-hue($accent-color,10%);
//       border-color: adjust-hue($accent-color,10%);
//       color: $text-dark-dm-color;
//     }
//   }

//   .button--light {
//     background: transparent;
//     border-color: $text-dark-dm-color;
//     color: $text-dark-dm-color;

//     &:hover {
//       background: $accent-dm-color;
//       border-color: $accent-dm-color;
//       color: $background-dm-color;
//     }
//   }

//   .button--overlay {
//     background: transparent;
//     border-color: $overlay-text-dm-color;
//     color: $overlay-text-dm-color;

//     &:hover {
//       background: $overlay-text-dm-color;
//       border-color: $overlay-text-dm-color;
//       color: $accent-dm-color;
//     }
//   }

//   hr {
//     background: $border-dm-color;
//   }

//   ::selection    { background: $accent-dm-color; color: $background-dm-color; }
//   ::-moz-selection { background: $accent-dm-color; color: $background-dm-color; }

//   .loader {
//     svg {
//       fill: rgba($text-dark-color,0.2);
//     }
//   }

//   img {
//     filter: grayscale(30%);
//   }
// }
