// - - - - - - - - - - Foundations

@import "_reset";
@import "_mixins";
@import "_plugins";



// - - - - - - - - - - Variables


// Colors

$background-color: #F9F9F9;
$background-alt-color: #F5F7FA;
$border-color: #DDDDDD;

$text-dark-color: #24536B;
$text-medium-color: #616D7B;
$text-light-color: #798795;

$accent-color: #C66B09;

$overlay-background-color: #161B21;
$overlay-text-color: #FFFFFF;

$error-color: #D64541;


// Colors - Dark Mode

$background-dm-color: #2c404a;
$background-alt-dm-color: #24536B;
$border-dm-color: #7b7b7b;

$text-dark-dm-color: #e1e4e7;
$text-medium-dm-color: #d0d4da;
$text-light-dm-color: #95c3dc;

$accent-dm-color: #a87640;

$overlay-background-dm-color: #e5e9ee;
$overlay-text-dm-color: #000000;

$error-dm-color: #952320;


// Fonts

$body-font: "Crimson Text", "Hoefler Text", "Georgia", serif;
$title-font: "Source Sans Pro", "Open Sans", "Helvetica Neue", Arial, sans-serif;

$regular-weight: 400;
$bold-weight: 700;


// Typography

$header-title-text-transform: uppercase;
$menu-text-transform: uppercase;

$sitetitle-small: 25px;
$sitetitle-medium: 30px;
$sitetitle-large: 35px;
$sitetitle-weight: 700;
$sitetitle-spacing: 0.05em;

$sitetagline-small: 18px;
$sitetagline-medium: 19px;
$sitetagline-large: 20px;
$sitetagline-weight: 400;
$sitetagline-spacing: 0em;
$sitetagline-lineheight: 1.5;

$menu-small: 30px;
$menu-medium: 17px;
$menu-large: 18px;
$menu-weight: 700;
$menu-spacing: 0.05em;

$h1-small: 25px;
$h1-medium: 35px;
$h1-large: 45px;
$h1-weight: 400;
$h1-spacing: -0.02em;
$h1-lineheight: 1.2;

$h2-small: 25px;
$h2-medium: 35px;
$h2-large: 45px;
$h2-weight: 400;
$h2-spacing: -0.02em;
$h2-lineheight: 1.2;

$h3-small: 22px;
$h3-medium: 25px;
$h3-large: 30px;
$h3-weight: 400;
$h3-spacing: -0.02em;
$h3-lineheight: 1.3;

$h4-small: 17px;
$h4-medium: 22px;
$h4-large: 22px;
$h4-weight: 400;
$h4-spacing: 0em;
$h4-lineheight: 1.6;

$h5-small: 17px;
$h5-medium: 20px;
$h5-large: 20px;
$h5-weight: 400;
$h5-spacing: 0em;
$h5-lineheight: 1.6;

$h6-small: 17px;
$h6-medium: 20px;
$h6-large: 20px;
$h6-weight: 400;
$h6-spacing: 0em;
$h6-lineheight: 1.6;

$p-small: 18px;
$p-medium: 22px;
$p-large: 24px;
$p-weight: 400;
$p-spacing: 0em;
$p-lineheight: 1.6;

$blockquote-small: 25px;
$blockquote-medium: 35px;
$blockquote-large: 45px;
$blockquote-weight: 400;
$blockquote-spacing: -0.02em;
$blockquote-lineheight: 1.6;

$button-weight: 600;
$button-spacing: 0em;


// Header
$overlay-opacity: 0.7;


// Logo
$logo-width: 200px;
$footer-logo-width: px;


// Grid item
$grid-spacing: 10px;



// - - - - - - - - - - Global styles

@import "_basic";
@import "_colours";

@import "_includes/_header";
@import "_includes/_footer";

@import "_includes/_content";
@import "_includes/_socials";
@import "_includes/_gallery";
@import "_includes/_syntax";
@import "_includes/_contact-form";



// - - - - - - - - - - Section styles

@import "_includes/_portfolio";
@import "_includes/_blog";


